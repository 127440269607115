body::-webkit-scrollbar {
  width: 10px;
  max-height: 200px;
  margin: 3px;
}

body::-webkit-scrollbar-thumb {
  background: #babebc;
  border-radius: 10px;
}

.scroll-smooth::-webkit-scrollbar {
  width: 5px;
  height: 15px;
  max-height: 200px;
  margin: 3px;
}
.scroll-smooth::-webkit-scrollbar-thumb {
  background: #cacbce;
  border-radius: 10x;
}

.scroll-smooth-small::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  max-height: 200px;
  margin: 3px;
}
.scroll-smooth-small::-webkit-scrollbar-thumb {
  background: #cacbce;
  border-radius: 10px;
}

.scroll-smooth-hide::-webkit-scrollbar {
  width: 0;
  height: 0;
  max-height: 0;
  margin: 0;
}
.scroll-smooth-hide::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 0;
}

#quill-mention-list {
  max-height: 250px;
  overflow: scroll;
}

.e-pv-notification-popup.e-control.e-dialog.e-lib.e-dlg-modal.e-popup.e-pv-notification-large-content.e-popup-open {
  display: none !important;
}

.maxLineText {
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.edgebutton {
  width: 20px;
  height: 20px;
  background: #eee;
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  font-size: 12px;
  line-height: 1;
}

.edgebutton:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}

.edgebutton-foreignobject body {
  background: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
}

.dndflow {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.dndflow aside {
  border-right: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  background: #fcfcfc;
}

.dndflow aside .description {
  margin-bottom: 10px;
}

.dndflow .dndnode {
  height: 20px;
  padding: 4px;
  border: 1px solid #1a192b;
  border-radius: 2px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
}

.dndflow .dndnode.input {
  border-color: #0041d0;
}

.dndflow .dndnode.output {
  border-color: #ff0072;
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}

.dndflow .selectall {
  margin-top: 10px;
}

.mention {
  color: #358ada !important;
  background-color: transparent;
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }

  .dndflow aside {
    width: 20%;
    max-width: 250px;
  }
}

.ql-formats {
  margin-right: -5px !important;
}

.ql-formats {
  margin-right: -5px !important;
}

.ant-popover {
  z-index: 1450 !important;
}

.ant-picker-dropdown.ant-picker-dropdown-range {
  z-index: 1550 !important;
}

/* Définir l'animation de clignotement */
@keyframes clignoter {
  0% {
    opacity: 1;
  } /* Opacité à 100% */
  50% {
    opacity: 0;
  } /* Opacité à 0% */
  100% {
    opacity: 1;
  } /* Opacité à 100% */
}

/* Appliquer l'animation à l'élément avec une durée et un délai */
.clignotant {
  animation: clignoter 1s ease-in-out infinite; /* Animation pendant 1 seconde, en boucle */
}

.ql-container.ql-snow {
  position: relative;
}

.ql-container.ql-bubble {
  position: relative;
}

.ql-tooltip {
  box-shadow: 0.2px 0.2px 1px #ccc, -0.2px -0.2px 2px #ccc !important;
}

.ql-tooltip.ql-editing {
  top: 0 !important;
  left: 0 !important;
}

.ql-bubble .ql-tooltip {
  left: 0 !important;
  height: 40px !important;
}
.ql-bubble .ql-tooltip-arrow {
  display: none;
}

.ql-bubble .ql-stroke {
  stroke: #fff;
}
.ql-bubble .ql-stroke:hover {
  stroke: #fff;
}

.toolbar.toolbar-view.folded.z-clear .logo {
  opacity: 0;
}
